const FooterData = {
  socialLinks: {
    github: "https://github.com/thedarkstrix",
    linkedIn: "https://www.linkedin.com/in/manoj-paramesh",
    twitter: "https://twitter.com/thedarkstrix",
    xda: "https://xdaforums.com/m/thedarkstrix.6800596/",
    kernelLink: "https://github.com/TheDarkStrix/android_kernel_xiaomi_msm8956",
    androidFileHost:
      "https://androidfilehost.com/?w=profile&uid=24457741883933363",
  },
};

const LookingForJob = {
  mightGetBrokeSoon: true,
};

export { FooterData, LookingForJob };
